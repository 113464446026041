const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const BookDemoHeader = () => {
  return (
    <div
      className={`relative w-full  bg-[url( ${digitalOceanURL}/ContactUsPageAssest/header-bg.svg)]  bg-cover bg-no-repeat h-[600px] header-container`}
    >
      <div className="header-content m-auto max-w-4xl absolute inset-0  flex flex-col justify-center items-center text-center">
        <h1 className="text-[30px] md:text-[54px] lg:text-[54px] font-medium text-white ">
          Schedule A Personalized Demo Call With Our Experts
        </h1>
      </div>
    </div>
  );
};

export default BookDemoHeader;
