
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const captchaImg = `${digitalOceanURL}/captchaimg.jpg`;


const CaptchaField = ({ name, onChange, iscorrect, captcha }) => {
  // console.log(captchaimg1)
  return (
    <div className="flex flex-col items-center mt-12 max-w-full text-base leading-relaxed text-center text-zinc-700  max-md:mt-10">
      <div className="flex items-center gap-2 md:gap-8  lg:gap-8 md:flex-row lg:flex-row flex-col  w-2/2">
        <div className="grow opacity-70">Please verify you are a human</div>
        <p width="400" height="50" className='p-2 text-center text-xl captcha' style={{ backgroundImage: `url(${captchaImg})`, fontSize: "30px", backgroundColor: "gray", color: "black", fontWeight: "bold" }}>{captcha}</p>
        <p style={{ display: iscorrect ? 'none' : 'block', color: 'red', fontSize: "12px" }} className="captcha_error">*captcha is wrong*</p>

      </div>
      <input
        className="flex px-2 shrink-0 mt-6 max-w-full bg-white rounded-lg border border-[#C8C9C9] focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]  h-[40px] w-[302px]"
        type="text"
        aria-label="CAPTCHA input"
        tabIndex="0"
        name={"captcha"}
        onChange={onChange}
      />
    </div>
  );
};
// border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]
//  className="w-full p-3 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
export default CaptchaField;
