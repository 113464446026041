import React from "react";

const TestimonialCard = ({ testimonial, renderStars }) => {
  return (
    <div
      key={testimonial.id}
      className="w-full text-center flex-shrink-0 px-8 "
    >
      <div className="text-center">
        <img loading="lazy"
          src={testimonial.image}
          alt={testimonial.author}
          width='75'
          height='75'
          // loading="lazy"
          className="w-24 h-24 rounded-full mx-auto mb-4 object-contain"
        />
        <div className="font-medium text-2xl text-[#163930] ">
          {testimonial.author}
        </div>
        <div className=" text-base font-medium text-[#111111] mb-2">
          {testimonial.position}
        </div>
        <div className="flex justify-center mb-2">
          {renderStars(testimonial.rating)}
        </div>
      </div>
      <div className="relative  lg:w-[80%] mx-auto">
        <svg
          width="40"
          height="37"
          viewBox="0 0 41 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.24"
            d="M36.4883 18.9619H30.2383V13.8191C30.2383 10.9824 32.4805 8.6762 35.2383 8.6762H35.8633C36.9023 8.6762 37.7383 7.81638 37.7383 6.74763V2.89049C37.7383 1.82174 36.9023 0.961914 35.8633 0.961914H35.2383C28.332 0.961914 22.7383 6.71549 22.7383 13.8191V33.1048C22.7383 35.2342 24.418 36.9619 26.4883 36.9619H36.4883C38.5586 36.9619 40.2383 35.2342 40.2383 33.1048V22.8191C40.2383 20.6896 38.5586 18.9619 36.4883 18.9619ZM13.9883 18.9619H7.73828V13.8191C7.73828 10.9824 9.98047 8.6762 12.7383 8.6762H13.3633C14.4023 8.6762 15.2383 7.81638 15.2383 6.74763V2.89049C15.2383 1.82174 14.4023 0.961914 13.3633 0.961914H12.7383C5.83203 0.961914 0.238281 6.71549 0.238281 13.8191V33.1048C0.238281 35.2342 1.91797 36.9619 3.98828 36.9619H13.9883C16.0586 36.9619 17.7383 35.2342 17.7383 33.1048V22.8191C17.7383 20.6896 16.0586 18.9619 13.9883 18.9619Z"
            fill="#163930"
          />
        </svg>

        <p className="text-[#111111]    md:ml-8  text-balance text-base font-normal relative z-10">
          {testimonial.content}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
