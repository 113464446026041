import { useNavigate } from "react-router-dom";
// import k from  './web.jpeg'
import { memo } from "react";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

// const leftIcon = `${digitalOceanURL}/FooterAssets/HelpCard/leftIcon.svg`;
const leftIcon = `${digitalOceanURL}/FooterAssets/HelpCard/leftIcon.svg`;

const rightIcon = `${digitalOceanURL}/FooterAssets/HelpCard/rightIcon.svg`;
// --------------------------------
const SmallScreenRightIcon = `${digitalOceanURL}/FooterAssets/HelpCard/right-smallIcon.svg`;
const SmallScreenLeftIcon = `${digitalOceanURL}/FooterAssets/HelpCard/bottom-smallIcon.svg`;

const HelpCard = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-3 ">
      <div className=" relative   max-w-4xl overflow-hidden rounded-[26px]  shadow-lg border border-[#cccccc] mx-auto lg:py-16 py-12 md:py-14  px-3 md:px-10 lg:px-10 flex md:flex-row  flex-col items-center justify-between bg-white">
        <img loading="lazy"
          src={leftIcon}
          alt="left hirestar Icon"
          className="absolute -top-5 invisible md:visible left-0"
        />

        {/* ---------------------------------- */}
        <img loading="lazy"
          src={SmallScreenLeftIcon}
          alt="SmallScreenLeft hirestar Icon"
          className="absolute w-[12%]  visible md:invisible bottom-0 right-0 left-0"
        />

        {/* -----------------leftContent---------------------- */}
        <div className="md:text-left z-20 text-center text-pretty">
          <h2 className="font-semibold  section_headings md:text-[40px] lg:text-[48px] text-[#2A2A2C] mb-3">
            Help Center
          </h2>
          <p className="text-black w-full md:w-3/4 font-base">
            Our <b>team</b> of <b>experts</b> is here to assist you.
            <b> Contact us today!</b>
          </p>
        </div>

        {/* ------------------right content------------------------------ */}
        <div className="rounded-full px-4 py-2 help_center text-white z-20 ">
          <button
            onClick={() => navigate('/book-demo')}
            className="About_btn "
          >
            Go to Help Center
          </button>
        </div>

        <img loading="lazy"
          src={rightIcon}
          alt="right hirestarIcon"
          className="absolute invisible md:visible top-1 right-0"
        />

        {/* --------------------------- */}
        <img loading="lazy"
          src={SmallScreenRightIcon}
          alt="SmallScreen hirestar RightIcon"
          className="absolute visible md:invisible top-6 right-2"
        />
      </div>
    </div>
  );
};

export default memo(HelpCard);
