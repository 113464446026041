const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const icon = `${digitalOceanURL}/icon.svg`;

const descreetInterviewData = [
  {
    heading: "Employment History Verification",
    text: "Confidentially confirms a candidate's previous job roles, responsibilities, and employment dates by speaking with former supervisors, managers, or HR personnel.",
    icon: icon
  },
  {
    heading: "Professional Reputation Check",
    text: "Gather insights into the candidate’s reputation within the industry by contacting former colleagues, business associates, or clients to assess their credibility, work ethic, and trustworthiness.",
    icon: icon
  },
  {
    heading: "Performance Evaluation",
    text: "Collects feedback on the candidate's past performance, work quality, ability to meet deadlines, and problem-solving skills by interviewing former supervisors or colleagues.",
    icon: icon
  },
  {
    heading: "Behavioural Assessment",
    text: "Reviews the candidate’s attitude, interpersonal skills, and behaviour in the workplace through feedback from peers or subordinates to identify any red flags.",
    icon: icon
  },
  {
    heading: "Integrity and Ethics Check",
    text: "Assesses the candidate’s integrity, honesty, and ethical behaviour by discreetly inquiring about their decision-making, adherence to company policies, and moral conduct.",
    icon: icon
  },
  {
    heading: "Cultural Fit",
    text: "Investigates whether the candidate would align with the company’s culture by discussing their work style, adaptability, and interpersonal relationships in past workplaces.",
    icon: icon
  },
  {
    heading: "Skills and Competency Validation",
    text: "Verifies the candidate’s claimed skills, technical expertise, and proficiency through third-party feedback from professionals who have worked with them directly.",
    icon: icon
  },
  {
    heading: "Reason for Leaving Previous Jobs",
    text: "Confirms the candidate’s reasons for leaving past roles to ensure there are no hidden issues such as termination for misconduct, underperformance, or conflicts.",
    icon: icon
  },
  {
    heading: "Leadership and Management Ability",
    text: "For candidates applying for leadership roles, discreet interviews can help assess their management style, leadership effectiveness, and how they handled team dynamics.",
    icon: icon
  },
  {
    heading: "Financial Responsibility",
    text: "In specific industries or positions, discrete interviews may gather feedback on the candidate’s financial acumen and responsibility, especially if they handled budgets or financial matters.",
    icon: icon
  }
];

const BlueCollarService = [
  {
    heading: "Aadhaar Card Verification",
    text: "Aadhaar card verification is essential for confirming identity and ensuring secure access to services in India. Various methods make the process efficient and reliable, supporting both individuals and institutions.",
    icon: icon
  },
  {
    heading: "PAN Card Verification",
    text: "PAN Card Verification checks the authenticity of a Permanent Account Number (PAN) card, ensuring that the details match official records. It is often used for identity verification by employers and financial institutions.",
    icon: icon
  },
  {
    heading: "Employment Check",
    text: "An employment check is a verification process used by employers to confirm a candidate's work history and qualifications, often involving reference checks and background screenings to ensure accuracy and prevent fraud.",
    icon: icon
  },
  {
    heading: "Address Verification",
    text: "An address verification check confirms the accuracy of a person's address to ensure it is valid for identity verification, service eligibility, or regulatory compliance.",
    icon: icon
  },
  {
    heading: "Education Check",
    text: "An education check verifies a candidate's degrees and qualifications with educational institutions, commonly used by employers during hiring.",
    icon: icon
  },
  {
    heading: "Voter ID Verification",
    text: "Voter ID verification is the process of confirming a voter's     identity using their voter identification card. It ensures that the  individual is eligible to vote and helps prevent fraud during  elections.",
    icon: icon
  }
];

const WhiteCollarService = [
  {
    heading: "Education Check",
    text: "An education check verifies a candidate's degrees and qualifications with educational institutions, commonly used by employers during hiring.",
    icon: icon
  },
  {
    heading: "PAN Card Verification",
    text: "PAN Card Verification checks the authenticity of a Permanent Account Number (PAN) card, ensuring that the details match official records. It is often used for identity verification by employers and financial institutions.",
    icon: icon
  },
  {
    heading: "Bank Account Verification",
    text: "Bank account verification confirms the legitimacy and ownership of a bank account to prevent fraud and ensure accurate transactions. Methods include micro-deposits, bank statement uploads, and real-time checks through third-party services.",
    icon: icon
  },
  {
    heading: "Aadhaar Card Verification",
    text: "Aadhaar card verification is essential for confirming identity and ensuring secure access to services in India. Various methods make the process efficient and reliable, supporting both individuals and institutions.",
    icon: icon
  },
  {
    heading: "Employment Check",
    text: "An employment check is a verification process used by employers to confirm a candidate's work history and qualifications, often involving reference checks and background screenings to ensure accuracy and prevent fraud.",
    icon: icon
  },
  {
    heading: "Professional Reference Check",
    text: "A professional reference check involves contacting a candidate's former supervisors or colleagues to verify their work performance and skills, helping employers assess their suitability for a job.",
    icon: icon
  },
  {
    heading: "Voter ID Verification",
    text: "Voter ID verification confirms a voter's identity using their voter identification card. It ensures that the individual is eligible to vote and helps prevent fraud during elections.",
    icon: icon
  },
  {
    heading: "Address Verification Check",
    text: "An address verification check confirms the accuracy of a person's address to ensure it is valid for identity verification, service eligibility, or regulatory compliance.",
    icon: icon
  },
  {
    heading: "Police Verification",
    text: "Police verification is a process where law enforcement checks an individual's background, typically for criminal history or pending cases. It is often required for job applications, visas, or licences to ensure safety and security.",
    icon: icon
  },
  {
    heading: "Court Record Check",
    text: "A court record check reviews an individual's legal history to identify any criminal charges, convictions, or civil cases, often used for background screenings in employment or legal situations.",
    icon: icon
  },
  {
    heading: "Social Media Check",
    text: "A social media check involves reviewing a candidate's online profiles to assess their behaviour and character, helping employers gain insights beyond resumes and interviews.",
    icon: icon
  }
];

const GigWorkerService = [
  {
    heading: "Aadhaar Card Verification",
    text: "Aadhaar card verification is essential for confirming identity and ensuring secure access to services in India. Various methods make the process efficient and reliable, supporting both individuals and institutions.",
    icon: icon
  },
  {
    heading: "PAN Card Verification",
    text: "PAN Card Verification checks the authenticity of a Permanent Account Number (PAN) card, ensuring that the details match official records. It is often used for identity verification by employers and financial institutions.",
    icon: icon
  },
  {
    heading: "Employment Check",
    text: "An employment check is a verification process used by employers to confirm a candidate's work history and qualifications, often involving reference checks and background screenings to ensure accuracy and prevent fraud.",
    icon: icon
  },
  {
    heading: "Address Verification",
    text: "An address verification check confirms the accuracy of a person's address to ensure it is valid for identity verification, service eligibility, or regulatory compliance.",
    icon: icon
  },
  {
    heading: "Education Check",
    text: "An education check verifies a candidate's degrees and qualifications with educational institutions, commonly used by employers during hiring.",
    icon: icon
  },
  {
    heading: "Voter ID Verification",
    text: "Voter ID verification is the process of confirming a voter's     identity using their voter identification card. It ensures that the  individual is eligible to vote and helps prevent fraud during  elections.",
    icon: icon
  }
];

const MoonLightingService = [
  {
    heading: "Monitoring and Ongoing Checks",
    text: "Performs routine checks to ensure adherence to employment policies and catch any new moonlighting cases. Encourages employees to disclose secondary jobs for transparency and compliance.",
    icon: icon
  },
  {
    heading: "Employment Verification",
    text: "Verify employment details using UAN numbers, PAN cards, and Form 26AS from the Income Tax portal. Confirm job titles, responsibilities, and employment duration across various positions.",
    icon: icon
  },
  {
    heading: "Background Checks",
    text: "Reaches out to former employers to confirm employment, performance, and reasons for departure. Speaks with supervisors or HR to assess the candidate’s work ethics and dependability.",
    icon: icon
  },
  {
    heading: "Automated Checks",
    text: "Automated systems handle large data sets swiftly, cutting down verification time. They reduce errors for accurate results and integrate smoothly with HR systems for an efficient process.",
    icon: icon
  },
  {
    heading: "Legal and Compliance Checks",
    text: "Examines employment contracts to confirm compliance with non-compete clauses and obligations. Verifies adherence to legal and industry standards related to dual employment and moonlighting.",
    icon: icon
  },
  {
    heading: "Financial and Tax Records",
    text: "Reviews financial records and tax returns to confirm reported incomes and find any hidden sources. Cross-checks these with employment data to uncover inconsistencies suggesting dual employment.",
    icon: icon
  }
];

const SocialMediaService = [
  {
    heading: "Behavioural Assessment",
    text: "Evaluates the candidate’s online behaviour, including interactions, comments, and posts to identify any concerning or inappropriate content such as discriminatory remarks, violent behaviour, or harassment.",
    icon: icon
  },
  {
    heading: "Professionalism and Work Ethic",
    text: "Verifies if the candidate's online activity aligns with the values and culture of the company by reviewing professional platforms like LinkedIn to assess their qualifications, skills, and work history.",
    icon: icon
  },
  {
    heading: "Cultural Fit",
    text: "Assesses if the candidate's values, lifestyle, and personal interests align with the company’s culture based on their social media activity and public profiles.",
    icon: icon
  },
  {
    heading: "Criminal Activity or Risky Behaviour",
    text: "Searches for any indications of illegal activities or high-risk behaviours, such as drug use, explicit content, or associations with extremist groups.",
    icon: icon
  },
  {
    heading: "Reputation Check",
    text: "Reviews public opinions, endorsements, and comments about the candidate to determine their personal and professional reputation in the digital space.",
    icon: icon
  },
  {
    heading: "Verification of Employment and Education Claims",
    text: "Cross-checks the candidate's employment history and educational qualifications with official records and online profiles to ensure credibility.",
    icon: icon
  },
  {
    heading: "Networking and Associations",
    text: "Analyses the candidate's professional network and associations with industry leaders, organisations, or any questionable groups.",
    icon: icon
  },
  {
    heading: "Influence and Following",
    text: "Assesses the candidate’s online influence, including their followers and engagement rate, which can be particularly important for roles involving public-facing responsibilities.",
    icon: icon
  }
];

const DrugTestService = [
  {
    heading: "Drug Test Verification",
    text: "Workplace drug screening is an important process employers use to identify the presence of illicit substances or alcohol in the bodies of prospective or existing employees.",
    icon: icon
  },
  {
    heading: "4-Panel Drug Test Service",
    text: "The 4-panel drug test checks for marijuana, cocaine, opiates, and amphetamines. It’s commonly used in industries like transportation, retail, manufacturing, and IT to ensure safety and productivity.",
    icon: icon
  },
  {
    heading: "5-Panel Drug Test Service",
    text: "The 5-panel drug test detects marijuana, cocaine, opiates, amphetamines, and PCP. It's widely used for its ease, affordability, and ability to identify common substances of abuse.",
    icon: icon
  },
  {
    heading: "9-Panel Drug Test Service",
    text: "The 9-panel drug test screens for substances like marijuana, cocaine, opiates, amphetamines, and more. It’s ideal for industries needing in-depth screening, such as healthcare, construction, and finance.",
    icon: icon
  },
  {
    heading: "10-Panel Drug Test Service",
    text: "The 10-panel drug test screens for substances like marijuana, cocaine, opiates, and PCP. It's widely used in industries with strict safety regulations, such as aviation, oil and gas.",
    icon: icon
  },
  {
    heading: "12-Panel Drug Test Service",
    text: "The 12-panel drug test screens for substances like marijuana, cocaine, opiates, ecstasy, and oxycodone. It's commonly used in sectors with strict safety requirements like aviation, defense, and law enforcement.",
    icon: icon
  }
];

export {
  descreetInterviewData,
  BlueCollarService,
  WhiteCollarService,
  GigWorkerService,
  MoonLightingService,
  SocialMediaService,
  DrugTestService
};
