import React, { memo } from "react";
import { Link } from "react-router-dom";
const bluearrow =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/SingleBlogImg/bluearrows.svg";
const LinksShowingBlogTitle = ({ title }) => {
  return (
    <div className="flex items-center space-x-4 max-w-5xl mt-[14rem] lg:mx-36 mx-2 ">
      <Link to="/">
        <div className="pt-3">
          <p className="cursor-pointer font-medium text-xl">Home</p>
        </div>
      </Link>
      <img src={bluearrow} alt="Arrow-left blog navigation" title="navigation left arrow" />
      <Link to="/blogs">
        <div className="pt-3">
          <p className="cursor-pointer font-medium text-xl">Our Blog</p>
        </div>
      </Link>
      <img src={bluearrow} alt="Arrow-right blog navigation" title="navigation right arrow"/>
      <Link>
        <div className="pt-3">
          <p className="cursor-pointer text-[#1C8DCC] font-medium text-xl">
            {title?.length > 15 ? `${title.substring(0, 15)}...` : title}
          </p>
        </div>
      </Link>
    </div>

  );
};

export default memo(LinksShowingBlogTitle);
