
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
// import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const Game_Img = `${digitalOceanURL}/Sector/Games.png`;

export default function Games() {
  return (
    <div>
      <div className="relative  background_color ">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="game-img hero section"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">

            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing mx-2 lg:mx-0">
              <span className="industry_info">Game</span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                Reliable User Verification for Online Gaming Platforms</h1>
              <h2 className="about-text-para mt-2 ">
                Your trusted partner for handling high-volume user verification smoothly on gaming apps and websites.
              </h2>
              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:mt-0 bottom_space mb-20 lg:mb-0">
              <img src={Game_Img} alt="about-girl img for games sector" className="w-full h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>

      <div className="top_Space">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="Banking Sector" />
      {/* 4 section */}
      {/* <BgvStepsSection /> */}
      <ServicesCardSection Sector="Gaming Sector" Sector_cards='games' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>


  )
}
