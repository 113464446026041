const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const captchaImg = `${digitalOceanURL}/captchaimg.jpg`;

const CaptchaField = ({ name, onChange, iscorrect, captcha }) => {
  return (
    <div className="flex flex-col items-center mt-12 max-w-full text-base leading-relaxed text-center text-zinc-700  max-md:mt-10">
      <div className="flex items-center gap-2 md:gap-8  lg:gap-8 md:flex-row lg:flex-row flex-col  w-2/2">
        <div className="grow opacity-70">Please verify you are a human</div>
        {/*  <img loading="lazy"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/30424c465f29b0b610e7e6adb291e71c89ac5c991aa6c8a6bb2b25f82553a87c?placeholderIfAbsent=true&apiKey=b0a9ec046c494f13b6651788e2f60541"
          className="object-contain shrink-0 max-w-full aspect-[4.33] w-[134px]"
          alt="CAPTCHA verification"
        /> */}
        {/* ------------captcha------------------ */}
        <p width="400" height="50" className='p-2 text-center text-xl' style={{ backgroundImage: `url(${captchaImg})`, fontSize: "30px", backgroundColor: "gray", color: "black", fontWeight: "bold" }}>{captcha}</p>
        <p style={{ display: iscorrect ? 'none' : 'block', color: 'red', fontSize: "12px" }} >*captch is wrong*</p>
      </div>
      <input
        className="flex shrink-0 mt-6 max-w-full bg-white rounded-lg border border-[#C8C9C9] focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]  h-[40px] w-[302px]"
        type="text"
        aria-label="CAPTCHA input"
        tabIndex="0"
        name={name}
        onChange={onChange}
      />
    </div>
  );
};
// border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]
//  className="w-full p-3 border border-[#C8C9C9] rounded-lg focus:outline-none focus:border-[#1571B7] focus:shadow-sm focus:shadow-[#1571B7]"
export default CaptchaField;
