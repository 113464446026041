import React from "react";

const TableOfContents = ({ tableContents }) => {
  return (
    <div className="max-w-5xl  mx-auto sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto 2xl  p-6  border-t border-gray-500">
      <h2 className="text-4xl font-medium mb-6 text-[#272626]">
        Table of Contents
      </h2>
      <ol className="list-decimal  cursor-pointer list-inside mb-8 space-y-3">
        {tableContents.map((title, idx) => (
          <li key={idx} className="text-[#1C8DCC] font-medium text-xl  transition-colors">
            {title}
          </li>
        ))}
        {/* <li className="text-[#1C8DCC] font-medium text-xl  transition-colors">
            How Hirestar.io relies on technology for instant, candidate-first
            background screening?
          </li>
          <li className="text-[#1C8DCC] font-medium text-xl  transition-colors">
            Our Range of Employee Screening Services in Hyderabad and other
            cities include:
          </li>
          <li className="text-[#1C8DCC] font-medium text-xl transition-colors">
            Importance of Background Verification Services in Hyderabad
          </li>
          <li className="text-[#1C8DCC] font-medium text-xl  transition-colors">
            Are you looking for the best background screening company in
            Hyderabad?
          </li> */}
      </ol>
    </div>
  );
};

export default TableOfContents;
