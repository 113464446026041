import HomePageHeroSection from "./HomePageHeroSection/HomePageHeroSection";
import AboutSection from "./AboutSection/AboutSection";
import IndustrySection from "./IndustrySection/IndustrySection";
import OurClients from "./OurClientSection/OurClients";
import OurServiceSection from "./OurServiceSection/OurServiceSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import WhyHirestarSection from "./WhyHirestarSection/WhyHirestarSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
const HomePage = () => {
  return (
    <>
      <HomePageHeroSection />
      <div className="component_spacing">
        <AboutSection />
      </div>

      <IndustrySection />

      <OurClients />

      <OurServiceSection />

      <WhyHirestarSection />

      <TestimonialSection />
      <FaqComponent />
      <div className="relative top-28  left-0 right-0 help_card">
        <HelpCard />
      </div>
    </>
  );
};

export default HomePage;
