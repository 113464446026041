import { useState, useEffect } from "react";
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
// import img1 from '../../../assets/HomeImages/img1.png'
// import img2 from '../../../assets/HomeImages/img2.png'
// import img3 from '../../../assets/HomeImages/img3.png'
// import img4 from '../../../assets/HomeImages/img4.png'
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Home_Image = `${digitalOceanURL}/Home_Page_Image.png`;
const linearImage = `${digitalOceanURL}/linear-image.png`;
const img1 = `${digitalOceanURL}/HomeImages/img1.png`;
const img2 = `${digitalOceanURL}/HomeImages/img2.png`;
const img3 = `${digitalOceanURL}/HomeImages/img3.png`;
const img4 = `${digitalOceanURL}/HomeImages/img4.png`;

const images = [
  img1,
  img2,
  img3,
  img4
];

const HomePageHeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section className="relative overflow-hidden background_color">
      {/* Desktop and Tablet View */}
      <header className="lg:relative lg:top-0 flex flex-col lg:mx-8">
        <div className="flex flex-col lg:relative mx-auto leading-tight z-[2] main-box gap-4">
          <div className="home_spacing ">
            <div className="text-wrap mt-14 lg:mt-0 md:mt-20 text-heading-container font-semibold font-[poppins] text-center heading w-[70%] mx-auto">
              {/* <h1 className="text-[32px] md:text-[40px] lg:text-[46px]">
                Secure Your Hiring Process With{" "}
                <span className="text-[32px] md:text-[40px] lg:text-[46px]">
                  Blockchain Technology
                </span>
              </h1> */}
              {/* updated coded */}
              <h1 className="inline text-[32px] md:text-[40px] lg:text-[46px]  ">
                Secure Your Hiring Process With{" "}
              </h1>
              <span className="inline text-[32px] md:text-[40px] lg:text-[46px] ">
                Blockchain Technology
              </span>
            </div>
            <div className="text-wrap px-2 lg:px-0 md:px-0 font-[poppins] text-[16px] mt-4 md:mt-6 lg:mt-6 text-center paragraph">
              <p>
                Streamline hiring with secure, blockchain-based background
                checks. <br className="hidden md:block lg:block" />
                Trust Hirestar.io for verified and transparent employee
                screening.
              </p>
            </div>
          </div>
          <div className="mx-auto">
            <ButtonComponent />
          </div>
        </div>

        {/* Desktop Image */}
        <figure className="large-image">
          <img
            className="home-image sm:mb-12 lg:relative w-full home_image"
            alt="Blockchain-based hiring process illustration"
            src={Home_Image}
            title="Hirestar-Image"
            loading="lazy"
          />
          <img
            src={linearImage}
            alt="Decorative gradient1"
            className="gradient-image-top-left"
            title="Hirestar-Top-Image"
            loading="lazy"
          />
          <img
            src={linearImage}
            alt="Decorative gradient2"
            className="gradient-image-bottom-right"
            title="Hirestar-Bottom-Image"
            loading="lazy"
          />
        </figure>

        {/* Mobile Carousel */}
        <div className="relative w-full max-w-sm mx-auto small-image">
          <div className="overflow-hidden rounded-lg">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Slide ${index + 1} showing hiring benefits`}
                  className="w-full h-auto flex-shrink-0"
                  title={`Hirestar-carousel-Image-${index + 1}`}
                  loading="lazy"
                  width="150" // Explicit width
                  height="75" // Explicit height
                />
              ))}
            </div>
          </div>
        </div>
      </header>
    </section>
  );
};

export default HomePageHeroSection;
