import React, { useRef, useState } from "react";
import "./govt-api.style.css";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const id_verification = `${digitalOceanURL}/Industries/images/id.png`;
const verification = `${digitalOceanURL}/Industries/hover_images/Id-hover.png`;
const Left_img = `${digitalOceanURL}/Sector/Govt_API_bg.png`;
const shadow = `${digitalOceanURL}/Sector/shadow.png`;
const degree = `${digitalOceanURL}/Sector/degree.svg`;
const degree_hover = `${digitalOceanURL}/Sector/degree_hover.png`;
const medical_hover = `${digitalOceanURL}/Sector/Medical.png`;
const medical = `${digitalOceanURL}/Sector/medical_hover.png`;
const financial = `${digitalOceanURL}/Sector/financial.png`;
const financial_hover = `${digitalOceanURL}/Sector/financial_hover.png`;
const Employment = `${digitalOceanURL}/Sector/employment.png`;
const Employment_hover = `${digitalOceanURL}/Sector/employment_hover.png`;
const crime_hover = `${digitalOceanURL}/Sector/crime_hover.png`;
const crime = `${digitalOceanURL}/Sector/crime.png`;
// ----------------------------------------
const reference = `${digitalOceanURL}/Industry/Sector/ref-hover.png`;
const reference_hover = `${digitalOceanURL}/Industry/Sector/ref.png`;
const address = `${digitalOceanURL}/Industry/Sector/address-hover.png`;
const address_hover = `${digitalOceanURL}/Industry/Sector/address.png`;
const past_emp = `${digitalOceanURL}/Industry/Sector/pass-emp-hover.png`;
const past_emp_hover = `${digitalOceanURL}/Industry/Sector/pass-emp.png`;
const aadhaar_normal = `${digitalOceanURL}/Industries/hover_images/hover_aadhar.png`;
const aadhaar = `${digitalOceanURL}/Industries/images/Aadhar.png`;
const aadhaarDigilocker_normal = `${digitalOceanURL}/Industries/hover_images/hover_digilocker_aadhar.png`;
const aadhaarDigilocker = `${digitalOceanURL}/Industries/images/aadhar_digilocker.png`;

const driving_license_normal = `${digitalOceanURL}/Industries/hover_images/hover_DL.png`;
const driving_license = `${digitalOceanURL}/Industries/images/driving_license.png`;
const pan_card_normal = `${digitalOceanURL}/Industries/hover_images/hover_pan.png`;
const pan_card = `${digitalOceanURL}/Industries/images/pan.png`;

const pan_card_seeding_normal = `${digitalOceanURL}/Industries/hover_images/hover_pan_aadhar.png`;
const pan_card_seeding = `${digitalOceanURL}/Industries/images/pan_aadhar.png`;
const passport_normal = `${digitalOceanURL}/Industries/hover_images/hover_passport.png`;
const passport = `${digitalOceanURL}/Industries/images/passport.png`;

const voterId_normal = `${digitalOceanURL}/Industries/hover_images/hover_voter.png`;
const voterId = `${digitalOceanURL}/Industries/images/voter.png`;

const aadharMasking_normal = `${digitalOceanURL}/Industries/hover_images/hover_aadhar_masking.png`;
const aadharMasking = `${digitalOceanURL}/Industries/images/aadhar_masking.png`;

const aadharOffline_normal = `${digitalOceanURL}/Industries/hover_images/hover_aadhar_offline.png`;
const aadharOffline = `${digitalOceanURL}/Industries/images/aadhar_offline.png`;

const panDob_normal = `${digitalOceanURL}/Industries/hover_images/hover_pan_name_dob.png`;
const panDob = `${digitalOceanURL}/Industries/images/pan_name_dob.png`;
const aadhaarPapperles_normal = `${digitalOceanURL}/Industries/hover_images/hover_aadhaar_paperless.png`;
const aadhaarPapperles = `${digitalOceanURL}/Industries/images/aadhar_paperless.png`;
const electricity_normal = `${digitalOceanURL}/Industries/hover_images/hover_electricity_bill.png`;
const electricity = `${digitalOceanURL}/Industries/images/electricity_bill.png`;
const rc_normal = `${digitalOceanURL}/Industries/hover_images/hover_rc.png`;
const rc = `${digitalOceanURL}/Industries/images/rc.png`;
const email_normal = `${digitalOceanURL}/Industries/hover_images/hover_email.png`;
const email = `${digitalOceanURL}/Industries/images/email.png`;
const tenant_normal = `${digitalOceanURL}/Industries/hover_images/hover_tenant.png`;
const tenant = `${digitalOceanURL}/Industries/images/tenant.png`;
const stolen_vehicle_normal = `${digitalOceanURL}/Industries/hover_images/hover_stolen_bike.png`;
const stolen_vehicle = `${digitalOceanURL}/Industries/images/stolen_bike.png`;
// -------------------------------------------------------

const bank_statement = `${digitalOceanURL}/Industries/hover_images/hover_bankstatement.png`;
const bank = `${digitalOceanURL}/Industries/images/bank_statement.png`;
const upi_handle = `${digitalOceanURL}/Industries/images/upi_handle.png`;
const hover_upi = `${digitalOceanURL}/Industries/hover_images/hover_upi_handle.png`;
const experian_credit = `${digitalOceanURL}/Industries/images/experian_credit.png`;
const hover_experian_credit = `${digitalOceanURL}/Industries/hover_images/hover_experian_credit.png`;

// const address = `${digitalOceanURL}/Industries/images/address.png`;
const din = `${digitalOceanURL}/Industries/images/din.png`;
const udyog_aadhar = `${digitalOceanURL}/Industries/images/udyog_aadhar.png`;
const tin = `${digitalOceanURL}/Industries/images/tin.png`;
const gstin = `${digitalOceanURL}/Industries/images/gstin.png`;
const udhyog_aadhar_via_phone = `${digitalOceanURL}/Industries/images/udhyog_aadhar_via_phone.png`;
const face_api = `${digitalOceanURL}/Industries/images/face_api.png`;
const liveness = `${digitalOceanURL}/Industries/images/liveness.png`;
const court_check = `${digitalOceanURL}/Industries/images/court_check.png`;
const director_check = `${digitalOceanURL}/Industries/images/director_check.png`;
const global_check = `${digitalOceanURL}/Industries/images/global_check.png`;
const uan = `${digitalOceanURL}/Industries/images/uan.png`;
const epfo = `${digitalOceanURL}/Industries/images/epfo.png`;
const cv = `${digitalOceanURL}/Industries/images/cv.png`;
const dual_check = `${digitalOceanURL}/Industries/images/dual_check.png`;
// const employement_check = `${digitalOceanURL}/Industries/images/employement_check.png`;
const insurance_agent = `${digitalOceanURL}/Industries/images/insurance_agent.png`;
const nurse = `${digitalOceanURL}/Industries/images/nurse.png`;
const medical_validation = `${digitalOceanURL}/Industries/images/medical_validation.png`;
const medical_opinion_validation = `${digitalOceanURL}/Industries/images/medical_opinion_validation.png`;
const icsi = `${digitalOceanURL}/Industries/images/icsi.png`;
// const hover_experian_credit = `${digitalOceanURL}/Industries/hover_images/hover_experian_credit.png`;
// const hover_address = `${digitalOceanURL}/Industries/hover_images/hover_address.png`;
const hover_din = `${digitalOceanURL}/Industries/hover_images/hover_din.png`;
const hover_udyog_aadhar = `${digitalOceanURL}/Industries/hover_images/hover_udyog_aadhar.png`;
const hover_tin = `${digitalOceanURL}/Industries/hover_images/hover_tin.png`;
const hover_gstin = `${digitalOceanURL}/Industries/hover_images/hover_gstin.png`;
const hover_udhyog_aadhar_via_phone = `${digitalOceanURL}/Industries/hover_images/hover_udhyog_aadhar_via_phone.png`;
const hover_face_api = `${digitalOceanURL}/Industries/hover_images/hover_face_api.png`;
const hover_liveness = `${digitalOceanURL}/Industries/hover_images/hover_liveness.png`;
const hover_court_check = `${digitalOceanURL}/Industries/hover_images/hover_court_check.png`;
const hover_director_check = `${digitalOceanURL}/Industries/hover_images/hover_director_check.png`;
const hover_globaL_check = `${digitalOceanURL}/Industries/hover_images/hover_global_check.png`;
const hover_uan = `${digitalOceanURL}/Industries/hover_images/hover_uan.png`;
const hover_epfo = `${digitalOceanURL}/Industries/hover_images/hover_epfo.png`;
const hover_cv = `${digitalOceanURL}/Industries/hover_images/hover_cv.png`;
const hover_duaL_check = `${digitalOceanURL}/Industries/hover_images/hover_dual_check.png`;
// const hover_employement_check = `${digitalOceanURL}/Industries/hover_images/hover_employement_check.png`;
const hover_insurance_agent = `${digitalOceanURL}/Industries/hover_images/hover_insurance_agent.png`;
const hover_nurse = `${digitalOceanURL}/Industries/hover_images/hover_nurse.png`;
const hover_medical_validation = `${digitalOceanURL}/Industries/hover_images/hover_medical_validation.png`;
const hover_meidcal_opinion_validation = `${digitalOceanURL}/Industries/hover_images/hover_medical_opinion_validation.png`;
const hover_icsi = `${digitalOceanURL}/Industries/hover_images/hover_icsi.png`;

// ----------------- api array-----------------------------
const Govt_API_Cards = [
  {
    id: 1,
    heading: "ID Verification",
    top: "lg:top-20",
    md: "md:top-20",
    hover: id_verification,
    normal: verification
  },
  {
    id: 2,
    heading: "Degree Verification",
    // top: "md:top-20",
    hover: degree_hover,
    normal: degree
  },
  {
    id: 3,
    heading: "Drug Test",
    top: "lg:top-20",
    md: "md:top-20",
    hover: medical,
    normal: medical_hover
  },
  {
    id: 4,
    heading: "Financial Integrity Check",
    md: "financial_check",
    // top: "lg:-top-20",
    hover: financial,
    normal: financial_hover
  },
  {
    id: 5,
    heading: "Employment Verification",
    top: "lg:top-20",

    hover: Employment,
    normal: Employment_hover
  },
  {
    id: 6,
    heading: "Crime Check",
    top: "lg:-top-0",
    md: "md:top-20",
    hover: crime,
    normal: crime_hover
  },
  {
    id: 7,
    heading: "Past Employment Verification",
    top: "lg:mt-20",
    md: "past_emp",
    hover: past_emp,
    normal: past_emp_hover
  },
  {
    id: 8,
    heading: "Reference Check",
    top: "lg:-top-0",
    hover: reference_hover,
    normal: reference
    // hover: aadhaar,
    // normal: aadhaar_normal
  },
  {
    id: 9,
    heading: "Address Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: address_hover,
    normal: address
  },
  {
    id: 10,
    heading: "Aadhaar Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: aadhaar,
    normal: aadhaar_normal
  },
  {
    id: 11,
    heading: "Aadhaar Via Digilocker",
    top: "lg:top-0",
    hover: aadhaarDigilocker,
    normal: aadhaarDigilocker_normal
  },
  {
    id: 12,
    heading: "Driving License Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: driving_license,
    normal: driving_license_normal
  },

  {
    id: 13,
    heading: "PAN Card Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: pan_card,
    normal: pan_card_normal
  },
  {
    id: 14,
    heading: "PAN Aadhaar Seeding",
    top: "lg:-top-20",
    hover: pan_card_seeding,
    normal: pan_card_seeding_normal
  },
  {
    id: 15,
    heading: "Passport Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: passport,
    normal: passport_normal
  },
  {
    id: 16,
    heading: "Voter ID Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: voterId,
    normal: voterId_normal
  },

  // ----------
  {
    id: 17,
    heading: "Aadhaar Masking",
    top: "lg:top-0",
    hover: aadharMasking,
    normal: aadharMasking_normal
  },
  {
    id: 18,
    heading: "Aadhaar Paperless",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: aadhaarPapperles,
    normal: aadhaarPapperles_normal
  },
  {
    id: 19,
    heading: "Aadhaar Offline Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: aadharOffline,
    normal: aadharOffline_normal
  },
  {
    id: 20,
    heading: "PAN Name Dob",
    top: "lg:-top-20",
    hover: panDob,
    normal: panDob_normal
  },

  {
    id: 21,
    heading: "Electricity Bill API",
    top: "lg:top-0",
    md: "md:top-20",
    hover: electricity,
    normal: electricity_normal
  },
  {
    id: 22,
    heading: "RC Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: rc,
    normal: rc_normal
  },
  {
    id: 23,
    heading: "Email Verification",
    top: "lg:top-0",
    hover: email,
    normal: email_normal
  },
  {
    id: 24,
    heading: "Tenant Registration",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: tenant,
    normal: tenant_normal
  },
  // -----------
  {
    id: 25,
    heading: "Stolen Vehicle Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: stolen_vehicle,
    normal: stolen_vehicle_normal
  },
  {
    id: 26,
    heading: "Bank Statement Analysis",
    top: "lg:-top-20",
    hover: bank,
    normal: bank_statement
  },
  {
    id: 27,
    heading: "UPI Handle Verification",
    md: "md:top-20",
    top: "lg:top-0",
    hover: upi_handle,
    normal: hover_upi
  },
  {
    id: 28,
    heading: "Experian Credit Report",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: experian_credit,
    normal: hover_experian_credit
  },
  {
    id: 29,
    heading: "DIN ",
    top: "lg:top-0",
    hover: din,
    normal: hover_din
  },
  {
    id: 30,
    heading: "Udyog Aadhaar",
    md: "md:top-20",
    top: "lg:-top-20",
    hover: udyog_aadhar,
    normal: hover_udyog_aadhar
  },
  {
    id: 31,
    heading: "TIN Search",
    md: "md:top-20",
    top: "lg:top-0",
    hover: tin,
    normal: hover_tin
  },
  {
    id: 32,
    heading: "GSTIN",
    top: "lg:-top-20",
    hover: gstin,
    normal: hover_gstin
  },
  {
    id: 33,
    heading: "Udyog Aadhaar Via Phone",
    md: "md:top-20",
    top: "lg:top-0",
    hover: udhyog_aadhar_via_phone,
    normal: hover_udhyog_aadhar_via_phone
  },
  {
    id: 34,
    heading: "Face API Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: face_api,
    normal: hover_face_api
  },
  {
    id: 35,
    heading: "Liveness Check",
    top: "lg:top-0",
    hover: liveness,
    normal: hover_liveness
  },
  {
    id: 36,
    heading: "Court Check",
    md: "md:top-20",
    top: "lg:-top-20",
    hover: court_check,
    normal: hover_court_check
  },
  {
    id: 37,
    heading: "Defaulting Director Check",
    top: "lg:top-0",
    md: "md:top-20",
    hover: director_check,
    normal: hover_director_check
  },
  {
    id: 38,
    heading: "Global Sancations Check",
    top: "lg:-top-20",
    hover: global_check,
    normal: hover_globaL_check
  },
  {
    id: 39,
    heading: "UAN Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: uan,
    normal: hover_uan
  },
  {
    id: 40,
    heading: "EPFO Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: epfo,
    normal: hover_epfo
  },
  {
    id: 41,
    heading: "CV Validation",
    top: "lg:top-0",
    hover: cv,
    normal: hover_cv
  },
  {
    id: 42,
    heading: "Dual Employment Check",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: dual_check,
    normal: hover_duaL_check
  },
  {
    id: 43,
    heading: "Employment Default Check",
    top: "lg:top-0",
    md: "md:top-20",
    hover: email,
    normal: email_normal
  },
  {
    id: 44,
    heading: "Insurance Agent Verification",
    top: "lg:-top-20",
    hover: insurance_agent,
    normal: hover_insurance_agent
  },
  {
    id: 45,
    heading: "Nurses Verification",
    top: "lg:top-0",
    md: "md:top-20",
    hover: nurse,
    normal: hover_nurse
  },
  {
    id: 46,
    heading: "Medical Document Validation",
    md: "md:top-20",
    top: "lg:-top-20",
    hover: medical_validation,
    normal: hover_medical_validation
  },
  {
    id: 47,
    heading: "Medical Opinion Validation",
    top: "lg:top-0",
    hover: medical_opinion_validation,
    normal: hover_meidcal_opinion_validation
  },
  {
    id: 48,
    heading: "ICSI Verification",
    top: "lg:-top-20",
    md: "md:top-20",
    hover: icsi,
    normal: hover_icsi
  }
];

const GovtApiCards = () => {
  const [showAll, setShowAll] = useState(false);
  const newCardsRef = useRef(null); // Step 1: Create a reference

  // Initially, show 8 cards; when 'showAll' is true, show all cards
  const visibleCards = showAll ? Govt_API_Cards : Govt_API_Cards.slice(0, 8);

  const handleShowAll = (e) => {
    setShowAll(!showAll);
    setTimeout(() => {
      if (newCardsRef.current) {
        newCardsRef.current.scrollIntoView({ behavior: "smooth" }); // Step 2: Scroll to the new cards smoothly
      }
    }, 0);
  };

  return (
    <div className=" mt-4 mb-20 mx-4">
      <div className=" pt-16 pb-16 relative ">

        <img loading="lazy" src={Left_img} className="absolute -top-[35%] -left-1/2" alt="left-side img" />

        <div className="text-center mb-24 mt-20">
          <h2 className="IT_Headings">
            Choose Your Preferred Verification Service
          </h2>
          {/* <p className="IT_service_Para">
            Select from our range of comprehensive background verification services tailored to meet your unique needs. <br />
            we provide accurate and reliable insights to help you make informed decisions with confidence.
          </p> */}
        </div>

        <div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4 relative lg:mx-20">
          {visibleCards.map((card, index) => (
            <div
              key={card.id}
              className={`govtAPICard cursor-pointer relative ${card.top} ${card.md} group `}
              ref={index === 8 ? newCardsRef : null} // Step 3: Add ref to the 9th card to scroll to this position
            >
              <div className="Sector_Section_5_parent flex flex-col justify-center  items-center h-full overflow-hidden gap-4   px-1">
                {/* Image */}
                <div className="govtAPICard_child bg-[#E6F5FF] group-hover:bg-[#2096d3] rounded-full flex justify-center items-center w-24 h-24 transition-colors duration-300">
                  <img
                    loading="lazy"
                    src={card.hover}
                    alt={`${card.heading} hover icon`}
                    className="absolute transition-opacity duration-300 opacity-100 group-hover:opacity-0"
                  />

                  {/* Hover image */}
                  <img loading="lazy"
                    src={card.normal}
                    alt={`${card.heading} normal icon`}
                    className="absolute transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                  />
                </div>

                {/* Shadow */}
                <div className="h-1">
                  <img loading="lazy" title="shadow for services cards" src={shadow} alt={`${card.heading} shadow img`} className="object-cover" />
                </div>

                {/* Text */}
                <div>
                  <p className="govt_card_heading text-center mt-1">
                    {card.heading}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {!showAll && (
          <div className="viewAllButton md:mt-24 mt-12 flex justify-center">
            <button
              onClick={handleShowAll}
              className="rounded-full view_all text-white w-1/2 md:w-[20%] lg:w-[13%]"
            >
              View All
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GovtApiCards;
