import React, { useState, useEffect } from "react";
// import Travel from "../../Medical_Sector/Travel";
// import ECommerce from "../../Medical_Sector/E_Commerce";
const digitalOceanURL = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

// ---------------FMCG---
const fmcgfmcd1 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EducationVerification.png`;
const fmcgfmcd2 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EmploymentVerification.png`;
const fmcgfmcd3 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AadhaarVerificationcard.png`;
const fmcgfmcd4 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AddressVerification.png`;
const fmcgfmcd5 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PANVerification.png`;
const fmcgfmcd6 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PoliceVerification.png`;
const fmcgfmcd7 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/IndianCourtRecord.png`;
const fmcgfmcd8 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PassportVerification.png`;
const fmcgfmcd9 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/SocialMedia.png`;
const fmcgfmcd10 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/ProfessionalReference.png`;

// -------------ECommerce----------------
const ecommerceandretail1 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/GSTN.png`;
const ecommerceandretail2 = `${digitalOceanURL}/Industry/Industry_Logo/health/Director.png`;
const ecommerceandretail3 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/TIN.png`;
const ecommerceandretail4 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/ICSI.png`;
const ecommerceandretail5 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/PAN.png`;
const ecommerceandretail6 = `${digitalOceanURL}/Industry/Industry_Logo/health/Shop.png`;
const ecommerceandretail7 = `${digitalOceanURL}/Industry/Industry_Logo/health/CIBIL.png`;
const ecommerceandretail8 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Bank.png`;
const ecommerceandretail9 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/UdyogAadhar.png`;
const ecommerceandretail10 = `${digitalOceanURL}/Industry/Industry_Logo/health/GSTN.png`;


// --------------Travel----------------
const travelandhospitality1 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EducationVerification.png`;
const travelandhospitality2 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EmploymentVerification.png`;
const travelandhospitality3 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AadhaarVerificationcard.png`;
const travelandhospitality4 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AddressVerification.png`;
const travelandhospitality5 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PANVerification.png`;
const travelandhospitality6 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PoliceVerification.png`;
const travelandhospitality7 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/IndianCourtRecord.png`;
const travelandhospitality8 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PassportVerification.png`;
const travelandhospitality9 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/SocialMedia.png`;
const travelandhospitality10 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/ProfessionalReference.png`;


// ----------------IT-----------------
const it1 = `${digitalOceanURL}/Industry/Industry_Logo/IT/EducationVerification.png`;
const it2 = `${digitalOceanURL}/Industry/Industry_Logo/IT/EmploymentVerification.png`;
const it3 = `${digitalOceanURL}/Industry/Industry_Logo/IT/AadhaarVerification.png`;
const it4 = `${digitalOceanURL}/Industry/Industry_Logo/IT/AddressVerification.png`;
const it5 = `${digitalOceanURL}/Industry/Industry_Logo/IT/PANVerification.png`;
const it6 = `${digitalOceanURL}/Industry/Industry_Logo/IT/PoliceVerification.png`;
const it7 = `${digitalOceanURL}/Industry/Industry_Logo/IT/IndianCourtRecord.png`;
const it8 = `${digitalOceanURL}/Industry/Industry_Logo/IT/PassportVerification.png`;
const it9 = `${digitalOceanURL}/Industry/Industry_Logo/IT/SocialMedia.png`;
const it10 = `${digitalOceanURL}/Industry/Industry_Logo/IT/ProfessionalReference.png`;



// ----------------------health---------------------------
const health1 = `${digitalOceanURL}/Industry/Industry_Logo/health/EducationVerification.png`;
const health2 = `${digitalOceanURL}/Industry/Industry_Logo/health/EmploymentVerification.png`;
const health3 = `${digitalOceanURL}/Industry/Industry_Logo/health/AadhaarVerification.png`;
const health4 = `${digitalOceanURL}/Industry/Industry_Logo/health/AddressVerification.png`;
const health5 = `${digitalOceanURL}/Industry/Industry_Logo/health/PANVerification.png`;
const health6 = `${digitalOceanURL}/Industry/Industry_Logo/health/PoliceVerification.png`;
const health7 = `${digitalOceanURL}/Industry/Industry_Logo/health/IndianCourtRecord.png`;
const health8 = `${digitalOceanURL}/Industry/Industry_Logo/health/PassportVerification.png`;
const health9 = `${digitalOceanURL}/Industry/Industry_Logo/health/MedicalDocument.png`;
const health10 = `${digitalOceanURL}/Industry/Industry_Logo/health/NurseRegistration.png`;
const health11 = `${digitalOceanURL}/Industry/Industry_Logo/health/MedicalHover.png`;

// --------------------game---------------------------
const game1 = `${digitalOceanURL}/Industry/Industry_Logo/game/BankStatementCheck.png`;
const game2 = `${digitalOceanURL}/Industry/Industry_Logo/game/PANVerification.png`;
const game3 = `${digitalOceanURL}/Industry/Industry_Logo/game/AadhaarVerification.png`;
const game4 = `${digitalOceanURL}/Industry/Industry_Logo/game/AddressVerification.png`;
const game5 = `${digitalOceanURL}/Industry/Industry_Logo/game/AMLCompliance.png`;

// --------------------education---------------------------
const education1 = `${digitalOceanURL}/Industry/Industry_Logo/education/AadhaarVerification.png`;
const education2 = `${digitalOceanURL}/Industry/Industry_Logo/education/AddressVerification.png`;
const education3 = `${digitalOceanURL}/Industry/Industry_Logo/education/EducationVerification.png`;
const education4 = `${digitalOceanURL}/Industry/Industry_Logo/education/EmploymentVerification.png`;
const education5 = `${digitalOceanURL}/Industry/Industry_Logo/education/IndianCourtRecord.png`;
const education6 = `${digitalOceanURL}/Industry/Industry_Logo/education/PANVerification.png`;
const education7 = `${digitalOceanURL}/Industry/Industry_Logo/education/PassportVerification.png`;
const education8 = `${digitalOceanURL}/Industry/Industry_Logo/education/PoliceVerification.png`;
const education9 = `${digitalOceanURL}/Industry/Industry_Logo/education/ProfessionalReference.png`;
const education10 = `${digitalOceanURL}/Industry/Industry_Logo/education/SocialMedia.png`;


//  ---------------banking---------------------------
const img1 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Aadhar.png`;
const img2 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Voter.png`;
const img3 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/DL.png`;
const img4 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Livness.png`;
const img5 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/UdyogAadhar.png`;
const img6 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/AadharMasking.png`;
const img7 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Address.png`;
const img8 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/UAN.png`;
const img9 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/PAN.png`;
const img10 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/GSTN.png`;
const img11 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/DIN.png`;
const img12 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/ICSI.png`;
const img13 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/Bank.png`;
const img14 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/EPFO.png`;
const img15 = `${digitalOceanURL}/Industry/Industry_Logo/Banking_Logo/TIN.png`;

// -------------------manufacturing------------------
const manufacturing1 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EducationVerification.png`;
const manufacturing2 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/EmploymentVerification.png`;
const manufacturing3 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AadhaarVerificationcard.png`;
const manufacturing4 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/AddressVerification.png`;
const manufacturing5 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PANVerification.png`;
const manufacturing6 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PoliceVerification.png`;
const manufacturing7 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/IndianCourtRecord.png`;
const manufacturing8 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/PassportVerification.png`;
const manufacturing9 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/SocialMedia.png`;
const manufacturing10 = `${digitalOceanURL}/Industry/Industry_Logo/Manufacturing/ProfessionalReference.png`;


// Define card arrays for each sector

// Banking Sector_cards Cards
const bankingCards = [
  { id: 1, heading: "Aadhaar Verification", img: img1 },
  { id: 2, heading: "Voter ID Verification", img: img2 },
  { id: 3, heading: "DL Verification", img: img3 },
  { id: 4, heading: "Liveness Verification", img: img4 },
  { id: 5, heading: "Udyog Aadhaar", img: img5 },
  { id: 6, heading: "Aadhaar Masking", img: img6 },
  { id: 7, heading: "Address Verification", img: img7 },
  { id: 8, heading: "UAN", img: img8 },
  { id: 9, heading: "PAN Verification", img: img9 },
  { id: 10, heading: "GSTIN Verification", img: img10 },
  { id: 11, heading: "DIN Verification", img: img11 },
  { id: 12, heading: "ICSI Verification", img: img12 },
  { id: 13, heading: "Bank Statement Analyzer", img: img13 },
  { id: 14, heading: "EPFO Verification", img: img14 },
  { id: 15, heading: "TIN", img: img15 },
];

// Travel Sector_cards Cards
const Education_Cards = [
  { id: 1, heading: "Education Verification", img: education3 },
  { id: 2, heading: "Employment Verification", img: education4 },
  { id: 3, heading: "Aadhaar verification", img: education1 },
  { id: 4, heading: "Address Verification", img: education2 },
  { id: 5, heading: "Police Verification", img: education8 },
  { id: 6, heading: "Indian Court Record", img: education5 },
  { id: 7, heading: "Passport Verification", img: education7 },
  { id: 8, heading: "Social Media", img: education10 },
  { id: 9, heading: "Professional Reference", img: education9 },
  { id: 10, heading: "PAN verification", img: education6 },

  // Add more travel cards as needed
];

// Self Verification Sector_cards Cards
const Game_Cards = [
  { id: 1, heading: "Bank Statement Check", img: game1 },
  { id: 2, heading: "PAN verification", img: game2 },
  { id: 3, heading: "Aadhaar verification", img: game3 },
  { id: 4, heading: "Address Verification", img: game4 },
  { id: 5, heading: "AML Compliance", img: game5 },

  // Add more self verification cards as needed
];


const Health_Cards = [
  { id: 1, heading: "Nurse Registration", img: health10 },
  { id: 2, heading: "Medical Document", img: health9 },
  { id: 3, heading: "Drug Test", img: health11 },
  { id: 4, heading: "Address Verification", img: health4 },
  { id: 5, heading: "Aadhaar verification", img: health3 },
  { id: 6, heading: "Police Verification", img: health6 },
  { id: 7, heading: "Indian Court Record", img: health7 },
  { id: 9, heading: "Employment Verification", img: health2 },
  { id: 8, heading: "PAN verification", img: health5 }, //
  { id: 10, heading: "Education Verification", img: health1 },
  { id: 11, heading: "Passport Verification", img: health8 },


  // Add more travel cards as needed
];


const Manufacturing_Cards = [
  { id: 1, heading: "Education Verification", img: manufacturing1 },
  { id: 2, heading: "Employment Verification", img: manufacturing2 },
  { id: 3, heading: "Aadhaar verification", img: manufacturing3 },

  { id: 4, heading: "Address Verification", img: manufacturing4 },
  { id: 5, heading: "PAN verification", img: manufacturing5 }, //
  { id: 6, heading: "Police Verification", img: manufacturing6 },
  { id: 7, heading: "Indian Court Record", img: manufacturing7 },
  { id: 8, heading: "Passport Verification", img: manufacturing8 },
  { id: 9, heading: "Social Media", img: manufacturing9 },
  { id: 10, heading: "Professional Reference", img: manufacturing10 },

  // Add more travel cards as needed
];



const It_Cards = [
  { id: 1, heading: "Education Verification", img: it1 },
  { id: 2, heading: "Employment Verification", img: it2 },
  { id: 3, heading: "Aadhaar verification", img: it3 },
  { id: 4, heading: "Address Verification", img: it4 },
  { id: 5, heading: "PAN verification", img: it5 }, //
  { id: 6, heading: "Police Verification", img: it6 },
  { id: 7, heading: "Indian Court Record", img: it7 },
  { id: 8, heading: "Passport Verification", img: it8 },
  { id: 9, heading: "Social Media", img: it9 },
  { id: 10, heading: "Professional Reference", img: it10 },

  // Add more travel cards as needed
];

const TravelAndHospitality_Cards = [
  { id: 1, heading: "Education Verification", img: travelandhospitality1 },
  { id: 2, heading: "Employment Verification", img: travelandhospitality2 },
  { id: 3, heading: "Aadhaar verification", img: travelandhospitality3 },
  { id: 4, heading: "Address Verification", img: travelandhospitality4 },
  { id: 5, heading: "PAN verification", img: travelandhospitality5 }, //
  { id: 6, heading: "Police Verification", img: travelandhospitality6 },
  { id: 7, heading: "Indian Court Record", img: travelandhospitality7 },
  { id: 8, heading: "Passport Verification", img: travelandhospitality8 },
  { id: 9, heading: "Social Media", img: travelandhospitality9 },
  { id: 10, heading: "Professional Reference", img: travelandhospitality10 },

  // Add more travel cards as needed
];


const EcommerceAndRetail_Cards = [
  { id: 1, heading: "GSTIN Verification", img: ecommerceandretail1 },
  { id: 2, heading: "Defaulting Directors", img: ecommerceandretail2 },
  { id: 3, heading: "CIN Verification", img: ecommerceandretail3 },
  { id: 4, heading: "FSSAI Verification", img: ecommerceandretail4 },
  { id: 5, heading: "PAN verification", img: ecommerceandretail5 }, //
  { id: 6, heading: "Shops & Establishment Verification", img: ecommerceandretail6 },
  { id: 7, heading: "CIBIL Verification", img: ecommerceandretail7 },
  { id: 8, heading: "Bank Statement Verification", img: ecommerceandretail8 },
  { id: 9, heading: "Udyam Aadhaar Verification​", img: ecommerceandretail9 },
  { id: 10, heading: "GSTIN View and Track Return", img: ecommerceandretail10 },

  // Add more travel cards as needed
];

const FmcgFmcd_Cards = [
  { id: 1, heading: "Education Verification", img: fmcgfmcd1 },
  { id: 2, heading: "Employment Verification", img: fmcgfmcd2 },
  { id: 3, heading: "Aadhaar verification", img: fmcgfmcd3 },
  { id: 4, heading: "Address Verification", img: fmcgfmcd4 },
  { id: 5, heading: "PAN verification", img: fmcgfmcd5 }, //
  { id: 6, heading: "Police Verification", img: fmcgfmcd6 },
  { id: 7, heading: "Indian Court Record", img: fmcgfmcd7 },
  { id: 8, heading: "Passport Verification", img: fmcgfmcd8 },
  { id: 9, heading: "Social Media", img: fmcgfmcd9 },
  { id: 10, heading: "Professional Reference", img: fmcgfmcd10 },

  // Add more travel cards as needed
];

// Mapping of sectors to their respective card arrays
const sectorCardsMap = {
  banking: bankingCards,
  education: Education_Cards,
  games: Game_Cards,
  manufacture: Manufacturing_Cards,
  it: It_Cards,
  health: Health_Cards,
  fmcg: FmcgFmcd_Cards,
  ecom: EcommerceAndRetail_Cards,
  travel: TravelAndHospitality_Cards

  // Add more mappings here as needed
};


export default function ServicesCardSection({ Sector, Sector_cards }) {
  const [showAll, setShowAll] = useState(false);
  // console.log("sector_cards", Sector_cards)

  // Normalize the Sector_cards prop to lowercase to match the keys in sectorCardsMap
  const normalizedSector = Sector_cards?.toLowerCase() || ''; // Safe access in case Sector_cards is undefined
  // Select the appropriate card array based on the Sector_cards prop
  const selectedCards = sectorCardsMap[normalizedSector] || [];

  // Determine the cards to display
  const visibleCards = showAll ? selectedCards : selectedCards.slice(0, 8);

  return (
    <div className="bg-[#E6F5FF] mt-32 mb-20">
      <div className="pt-16 pb-16">
        <section className="text-center mb-24 mt-20">
          <h2 className="IT_Headings" aria-label={`Background Verification Services for ${Sector}`}>
            Background Verification Services for <br /> {Sector}
          </h2>
        </section>

        <div className="grid lg:grid-cols-4 lg:mx-12 sm:grid-cols-1 gap-8 relative md:mx-8 mx-4">
          {visibleCards && visibleCards?.map((card) => (
            <div key={card.id} className="Sector_Section_5 relative">
              <div className="Sector_Section_5_parent flex flex-col justify-between items-center h-full overflow-hidden">
                <h6 className="Sector_Section_5_text mt-12 text-center">
                  {card.heading}
                </h6>
                <div className="Sector_Section_5_child bg-[#E6F5FF] rounded-t-full overflow-hidden absolute top-[77%] transform translate-y-[-50%] flex justify-center items-center w-24 h-24">
                  <img
                    loading="lazy"
                    src={card.img}
                    title={`IT_Service ${card.heading}`}
                    alt={`IT_Service ${card.id}`}
                    className="mt-2 object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {selectedCards.length > 8 && !showAll && (
          <div className="viewAllButton mt-8 flex justify-center">
            <button
              onClick={() => setShowAll(true)}
              className="rounded-full px-4 py-2 view_all text-white w-1/2 lg:w-[13%]"
            >
              View All
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

