import TestimonialComponent from "../../../Components/Testimonial/TestimonialComponent";
const TestimonialSection = () => {
  return (
    <>
      <TestimonialComponent />
    </>
  );
};

export default TestimonialSection;
