import { useNavigate } from "react-router-dom";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const sector_main_box_img = `${digitalOceanURL}/Industry/Sector/sector_main_box_img.png`;
const child_img1 = `${digitalOceanURL}/Industry/Sector/child_img1.png`;
const child_img2 = `${digitalOceanURL}/Industry/Sector/child_img2.png`;
const child_img3 = `${digitalOceanURL}/Industry/Sector/child_img3.png`;
const child_img4 = `${digitalOceanURL}/Industry/Sector/child_img4.png`;

const BgvStepsSection = () => {
  const navigate = useNavigate();
  /* Fix the Alignment in BGV step Component */
  return (
    <div className="mt-12">
      <img src={sector_main_box_img} alt="sector main box img " className=" absolute   sector_image " />

      {/*  <img loading="lazy" src={top_img} alt="" className='absolute ' /> */}

      <div className="grid lg:grid-cols-2 sm:grid-cols-1 sm:gap-8 About-box top-20 relative lg:mx-40 sm:mx-0 lg:justify-center sm:justify-start">
        <div className="lg:mt-20 lg:mb-0 mb-8 " aria-label="the steps involved in your verification">
          {/* <h2 className="industry_heading w-full text-[32px] md:text-[40px] lg:text-[46px]" aria-label="the steps involved in your verification">
            The steps <br />
            involved in your <br /> verification
          </h2> */}

          {/* updated heading */}
          <h2 className="industry_heading w-full text-[32px] md:text-[40px] lg:text-[46px]" >
            The Steps
          </h2>
          <h2 className="industry_heading w-full text-[32px] md:text-[40px] lg:text-[46px]" >
            Involved In Your
          </h2>
          <h2 className="industry_heading w-full text-[32px] md:text-[40px] lg:text-[46px]" >
            Verification
          </h2>
          {/* ------------------------ */}
          <p className="about-text-para side_space mt-4 text-center lg:text-left">
            Our verification involves seamless steps to ensure accuracy and
            compliance in IT/ITeS.
          </p>

          <div className="flex justify-center lg:justify-start mt-6 big_size">
            <button
              onClick={() => navigate('/book-demo')}
              class="rounded-full Speak_btn text-black p-3 mt-4"
            >
              Speak To An Expert
            </button>
          </div>
        </div>

        <div className="sector_main_box flex flex-col space-y-8 md:m-6 ">
          {/* Box 1: Image on the left */}
          <div className="sector_child_box flex items-center space-x-2 md:m-6 justify-center ">
            <img loading="lazy"
              src={child_img1}
              alt="candidate submission img"
              className="lg:ml-20 sm:ml-16"
            />
            <div className="mb-3">
              <h2 className="sector_child_box_heading">Candidate Submission</h2>
              <p className="sector_child_box_paragraph">
                Submit candidate details for verification
              </p>
            </div>
          </div>

          <div className="sector_child_box flex items-center space-x-2 justify-center ">
            <img loading="lazy"
              src={child_img2}
              alt="verification initiation img"
              className="lg:ml-6 sm:ml-40 "
            />
            <div className="mb-3">
              <h2 className="sector_child_box_heading">
                Verification Initiation
              </h2>
              <p className="sector_child_box_paragraph">
                Begin the verification process for submitted candidates.
              </p>
            </div>
          </div>

          <div className="sector_child_box flex items-center space-x-2 justify-center ">
            <img loading="lazy"
              src={child_img3}
              alt="real-time tracking img"
              className="lg:ml-16 sm:ml-16"
            />
            <div className="mb-3">
              <h2 className="sector_child_box_heading">Real-Time Tracking</h2>
              <p className="sector_child_box_paragraph">
                Monitor the verification status in real-time.
              </p>
            </div>
          </div>

          <div className="sector_child_box flex items-center space-x-2 justify-center ">
            <img loading="lazy"
              src={child_img4}
              alt="completion & reporting img"
              className="lg:ml-4 sm:ml-16"
            />
            <div className="mb-3">
              <h2 className="sector_child_box_heading">
                Completion & Reporting
              </h2>
              <p className="sector_child_box_paragraph">
                Receive final verification results and reports.
              </p>
            </div>
          </div>

          <div className="flex justify-center lg:justify-start mt-6 small_size">
            <button
              onClick={() => navigate('/book-demo')}
              className="rounded-full Speak_btn text-black p-3 mt-4"
            >
              Speak To An Expert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BgvStepsSection;
