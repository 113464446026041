const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

// const hyd_map = `${digitalOceanURL}/SingleBlogImg/hydmap.svg`;
// const arrow = `${digitalOceanURL}/SingleBlogImg/arrow.svg`;
// const map = `${digitalOceanURL}/SingleBlogImg/map.svg`;
const leftIcon = `${digitalOceanURL}/SingleBlogImg/leftRoundImg.svg`;
const rightIcon = `${digitalOceanURL}/SingleBlogImg/rigthRoundImg.svg`;

const Location = ({indiaMap,stateMap,state}) => {
  return (
    <div className="relative w-full mt-44 overflow-hidden flex justify-center">
      {/* Right Icon */}
      <div className="absolute right-0 top-0 h-auto w-[10%] md:w-[8%] lg:w-[5%]">
        <img loading="lazy" src={rightIcon} alt="right half Icon" className="w-full h-auto" />
      </div>

      {/* Centered Content */}
      <div >
        <div className="india-map shrink-0 block lg:hidden">
          <img
            src={indiaMap}
            alt="centered map india img"
            loading="lazy"
            className="max-w-full h-auto"
          />
        </div>

        {/* Hyderabad Map - visible on large screens only */}
        <div className="hyd-map shrink-0 gap-y-8 lg:gap-y-12 md:gap-y-12 hidden lg:flex flex-col items-center">
          {/* <h1 className="text-[#2095D2] font-bold text-4xl">{state}</h1> */}
          <img
            src={stateMap}
            loading="lazy"
            alt="centered map hyderabad img"
            className="max-w-full h-auto"
          />
        </div>
      </div>

      {/* Left Icon */}
      <div className="absolute left-0 bottom-0 h-auto w-[10%] md:w-[8%] lg:w-[5%]">
        <img loading="lazy" src={leftIcon} alt="left half Icon" className="w-full h-auto" />
      </div>
    </div>

  );
};

export default Location;
