import React, { useState, useEffect } from "react";
import { Star } from "lucide-react";
import './testimonial.style.css'
import styled from 'styled-components';


import TestimonialCard from "./TestimonialCard";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Samidha_Garud = `${digitalOceanURL}/TestimonialAsests/samidha-garud.jpg`;
const Vijay_Bawra = `${digitalOceanURL}/TestimonialAsests/vijay bawra.png`;
const Vijay_Kumari = `${digitalOceanURL}/TestimonialAsests/vijaya-kumari.jpg`;
const Dheeraj_Reddy = `${digitalOceanURL}/TestimonialAsests/polaris.jfif`;
const vincent = "https://hirestarorg.blr1.digitaloceanspaces.com/TestimonialAsests/vincint.jpg"
const zeba = "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/SingleBlogImg/monetra.jpeg "
// import k from '../../assets/TestimonialAsests'
const testimonials = [
  {
    id: 1,
    content:
      "The HireStar offer experience has been a game-changer for our HR team. We've saved countless hours using the prediction tool, and we're already seeing a noticeable improvement in our offer-to-join ratios. It's been a highly effective solution for streamlining our hiring process.",

    author: "Samidha Garud",
    position: "Co-founder Kanerika Inc",
    image: Samidha_Garud,
    rating: 5
  },
  {
    id: 2,
    content:
      "HireStar is a game-changer for recruiters. It helps prevent fake profiles, significantly speeding up the hiring process. With its efficient tools, the recruitment cycle time can be reduced from weeks to just a few days, making it a valuable asset for streamlining hiring efforts.",
    author: "Vijay Bawra",
    position:
      "Regional Head Nasscom 10000 startups program Andhra Pradesh & Telangana.",
    image: Vijay_Bawra,
    rating: 4
  },
  {
    id: 3,
    content:
      "Congratulations on the remarkable platform, HireStar! I am confident it will revolutionize recruitment and verification processes. With its blockchain foundation, the platform ensures secure and transparent hiring, and only new qualifications or skills need periodic verification. This innovation will open new doors for efficiency and reliability in the recruitment industry.",
    author: "Vijay Kumari",
    position: "Director JHUB, JNTU",
    image: Vijay_Kumari,
    rating: 5
  },
  {
    id: 4,
    content:
      "HireStar.io has truly transformed our recruitment process. By utilizing blockchain technology, it has ensured the authenticity of candidate profiles, offering a secure and transparent system. This has significantly reduced our HR team's workload, streamlining the hiring process and improving efficiency. HireStar.io is a game-changer for modern recruitment.",
    author: "Dheeraj Reddy",
    position: "Managing Director @Polaris Infratech Private Limited",
    image: Dheeraj_Reddy,
    rating: 5
  },
  {
    id: 5,
    content: "Among all the BGV vendors we've worked with, Hirestar has been outstanding, delivering exceptional results. We're extremely satisfied and highly impressed with your service.",
    author: "Mr. Vincent",
    position: "HR Head Goken",
    image: vincent,
    rating: 5
  },
  {
    id: 6,
    content: "With its intuitive design, our field team could independently complete verifications, ensuring accuracy, speed, and full compliance with industry standards. The thorough checks provided by Hirestar.io have not only mitigated risks but also enhanced our hiring decisions and fostered greater trust within our organization. A huge thank you to Hirestar.io team for making this possible.Highly recommend Hirestar.io to anyone seeking a seamless and reliable background verification solution!",
    author: "Zeba Parveen",
    position: "Senior HR Lead Monitra Healthcare Pvt Ltd.",
    image: zeba,
    rating: 5
  }
];

export default function TestimonialSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  // Ratings method
  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <Star
        key={index}
        className={`w-5 h-5 ${index < rating ? "text-yellow-400 fill-yellow-400" : "text-gray-300"
          }`}
      />
    ));
  };




  return (
    <div className="relative  w-full max-w-7xl  mx-auto px-4 py-8 ">
      <div className="bg-white  py-16 rounded-3xl box-shadow overflow-hidden">
        <div className="relative">
          {/* Four small round images in the corners */}
          <img loading="lazy"
            src={Vijay_Kumari}
            alt="vijay kumari img"
            width='75'
            height='75'
            className="absolute top-[calc(12%)] z-49 left-[calc(7%)] w-[55px] h-[55px] rounded-full hidden lg:block object-contain"
          />
          <img loading="lazy"
            src={Vijay_Bawra}
            alt="vijay bawra img"
            width='75'
            height='75'
            className="absolute top-[calc(12%)] z-49 right-[calc(5%)] w-[60px] h-[60px] rounded-full hidden lg:block object-contain"
          />

          <img loading="lazy"
            src={vincent}
            alt="vincent img"
            width='75'
            height='75'
            className="absolute top-[calc(50%)] z-49 right-[calc(15%)] w-[45px] h-[45px] rounded-full hidden lg:block object-contain"
          />

          <img loading="lazy"
            src={Samidha_Garud}
            alt="samidha garud img"
            width='75'
            height='75'
            className="absolute  bottom-[calc(-10%)]  left-[calc(5%-0.3rem)] z-49 w-[60px] h-[60px] rounded-full hidden lg:block object-contain"
          />
          <img loading="lazy"
            src={Dheeraj_Reddy}
            alt="dheeraj reddy img"
            width='75'
            height='75'
            className="absolute bottom-[calc(-16%)] z-49 right-[calc(10%-4rem)] w-[65px] h-[65px] rounded-full hidden lg:block object-contain"
          />
          {/* ------------------------------------------------------------------------------ */}
          {/* Testimonials card container */}
          {/* <div
            className="flex  transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {testimonials.map((testimonial) => (
              <TestimonialCard
                key={testimonial.id}
                testimonial={testimonial}
                renderStars={renderStars}
              />
            ))}
          </div> */}
          <SliderContainer currentIndex={currentIndex}>
            {testimonials.map((testimonial) => (
              <TestimonialCard
                key={testimonial.id}
                testimonial={testimonial}
                renderStars={renderStars}
              />
            ))}
          </SliderContainer>

          {/* -------------------------------------------------------- */}
        </div>
        <div className="px-8 lg:mt-6">
          <div className="flex justify-center space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-3 h-3 rounded-full transition-colors duration-200 ${index === currentIndex
                  ? "bg-[#1B8ACA] shadow-md border-[#1B8ACA] border-[0.6px]"
                  : "bg-white border-[0.6px] border-[#1B8ACA]"
                  }`}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}

          </div>
        </div>
      </div>
    </div>
  );
}

const SliderContainer = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => `translateX(-${props.currentIndex * 100}%)`};
`;

