// import React = 'react'
// import About_Section_Image = "../Portal_Image/GR_Section.png";
// import wireframe = "../Portal_Image/wireframe.png";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const About_Section_Image = ` ${digitalOceanURL}/Portal_Image/GR_Section.png`;
const wireframe = `${digitalOceanURL}/Portal_Image/wireframe.png`;

export default function GR_Section() {
  return (
    <div className="sm:mt-4 relative section_spacing2">
      <img
        src={wireframe}
        alt="wireframe img for grievance section"
        loading="lazy"
        className="hidden md:block absolute right-0 top-0"
      />

      <div className="grid lg:grid-cols-2 lg:mx-12 sm:grid-cols-1 sm:gap-8 About-box items-center justify-center relative lg:top-36">
        <div className="about-text-box text-center lg:text-left">
          <div>
            <button className="rounded-full text-black p-1 h-[37px] w-32 mb-2 mt-2 About-btn font-[16px]">
              Grievance Portal{" "}
            </button>
          </div>
          <div className="mt-2" aria-label="Simplify HR Tasks: Grievance Reporting Portal">
            {/* <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] About-Heading" aria-label="Simplify HR Tasks: Grievance Reporting Portal">
              Simplify HR Tasks: <br /><span className='text-[#1783C4]'> Grievance Reporting Portal </span>
            </h2> */}
            {/* updated ------------------- heading */}
            <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] About-Heading" aria-label="Simplify HR Tasks:">
              Simplify HR Tasks:
            </h2>
            <h2 className="font-semibold text-[32px] md:text-[48px] lg:text-[48px] text-[#1783C4] About-Heading" aria-label="Grievance Reporting Portal">
              Grievance Reporting Portal
            </h2>

          </div>
          <div className="mt-4">
            <p className="font-[poppins] font-[16px] lg:mx-0 md:mx-0 mx-3">
              Through Hirestar.io's Grievance Portal, employees and HR teams can efficiently report and address critical issues such as moonlighting, absconding, and offer shopping. This centralized system ensures timely action, fosters transparency, and helps maintain workplace integrity by enabling smooth resolution of grievances while safeguarding organizational values
            </p>
          </div>
          {/*  <div className="mt-4">
                        <button className="rounded-full text-white w-[134px] h-[52px] mb-2 mt-2 Read-More-btn font-[16px]">
                            Read More{" "}
                        </button>
                    </div> */}
        </div>
        <div className="flex justify-center lg:mx-0 md:mx-0 mx-2">
          <img loading="lazy" title="about us section image" src={About_Section_Image} alt="about us gr section img" />
        </div>
      </div>
    </div>


  );
}
