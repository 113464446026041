const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const client1 = `${digitalOceanURL}/Clients_PNG/Appzest.png`;
const client2 = `${digitalOceanURL}/Clients_PNG/ChitMonks-logo.png`;
const client3 = `${digitalOceanURL}/Clients_PNG/bluesemi-logo.png`;
// const client4 = `${digitalOceanURL}/Clients_PNG/camfil-logo.png`;
const client5 = `${digitalOceanURL}/Clients_PNG/crome-check-logo.png`;
const client6 = `${digitalOceanURL}/Clients_PNG/elogix-logo.png`;
// const client7 = `${digitalOceanURL}/Clients_PNG/first-view-logo.png`;
const client8 = `${digitalOceanURL}/Clients_PNG/t-hub-logo.png`;
const client9 = `${digitalOceanURL}/Clients_PNG/monitra-logo.png`;
const client10 = `${digitalOceanURL}/Clients_PNG/AriqT-logo.png`;
const client11 = `${digitalOceanURL}/Clients_PNG/JNTU.png`;
const client12 = `${digitalOceanURL}/Clients_PNG/appstek_corp_logo.png`;
const client13 = `${digitalOceanURL}/Clients_PNG/icici-bank-logo.png`;
const client14 = `${digitalOceanURL}/Clients_PNG/image 48.png`;
const client15 = `${digitalOceanURL}/Clients_PNG/image 49.png`;
const client16 = `${digitalOceanURL}/Clients_PNG/image 50.png`;
const client17 = `${digitalOceanURL}/Clients_PNG/kanerika.png`;
const client18 = `${digitalOceanURL}/Clients_PNG/startupindia.png`;
const client19 = `${digitalOceanURL}/Clients_PNG/startups.png`;

const client20 = `${digitalOceanURL}/NewClients/defa.svg`;
const client21 = `${digitalOceanURL}/NewClients/hpl.svg`;
const client22 = `${digitalOceanURL}/NewClients/91springboard.svg`;
const client23 = `${digitalOceanURL}/NewClients/blix.svg`;
const client24 = `${digitalOceanURL}/NewClients/goken.svg`;
const client25 = `${digitalOceanURL}/NewClients/jewelbox.svg`;
const client26 = `${digitalOceanURL}/NewClients/nasscom.svg`;
const client27 = `${digitalOceanURL}/NewClients/phenom.svg`;
const client28 = `${digitalOceanURL}/NewClients/sps.svg`;
const client29 = `${digitalOceanURL}/NewClients/tfg.svg`;
const client30 = `${digitalOceanURL}/NewClients/trade-fantasy.svg`;
const client31 = `${digitalOceanURL}/NewClients/volta.svg`;



const images = [
  client1,
  client2,
  client3,
  // client4,
  client5,
  client6,
  // client7,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
  client17,
  client18,
  client19,
  client20,
  client21,
  client22,
  client23,
  client24,
  client25,
  client26,
  client27,
  client28,
  client29,
  client30,
  client31,

];

// ------------------------------------------------------------------
const BrandCarousel = ({ marginTop }) => {
  return (
    <div className={`w-full mx-auto mt-[${marginTop}] overflow-hidden `}>
      {/* ---------------Heading----------------------------------- */}
      <div className="text-center mb-2  md:w-5/6 lg:w-3/5 mx-auto gap-2 md:gap-10 lg:gap-10 flex items-center  justify-between">
        <div className="flex-grow h-px bg-[#1681C3]"></div>
        <h2 className="font-medium text-xl text-[#000000]">
          Trusted By Leading Brands
        </h2>
        <div className="flex-grow  h-px bg-[#1681C3]"></div>
      </div>
      {/* -----------------------Slider-------------------------------- */}
      <div className="relative  w-full">
        <div className="animate-slide  transition-transform duration-300 ease-in-out gap-4 animate-slide-left flex">
          {[...images, ...images, ...images].map((src, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-[205px] h-[125px] 
           flex  justify-center items-center hover:cursor-pointer p-2  "
            >
              {" "}
              <div className="client-company-logo">
                <img
                  src={src}
                  loading="lazy"
                  alt={`Slide ${index + 1}`}
                  title={`client ${index + 1}`}
                  className="aspect-auto  mix-blend-color-burn"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandCarousel;
