import { Navigate, Route, Routes } from "react-router-dom";
// import React, { Suspense, lazy } from "react";
import NotFound from "../404/NotFound.jsx";
import HomePage from "../HomePage/HomePage.jsx";
import AboutSection from "../AboutUsPage/AboutUsPage.jsx";
import BlogsPage from "../BlogsPage/BlogsPage.jsx";
import ContactUsPage from "../ContactUsPage/ContactUsPage.jsx";
import Education from "../Medical_Sector/Education.jsx";
import Banking from "../Medical_Sector/Banking.jsx";
import BookDemoPage from "../BookDemo/BookDemoPage.jsx";
import Travel from "../Medical_Sector/Travel.jsx";
import ECommerce from "../Medical_Sector/E_Commerce.jsx";
import Games from "../Medical_Sector/Games.jsx";
import Manufacture from "../Medical_Sector/Manufacture.jsx";
import Logistics from "../Medical_Sector/Logistic.jsx";
import GigWorkers from "../Medical_Sector/Gig_workers.jsx";
import Industries from "../Medical_Sector/Industries.jsx";

import SingleBlogPage from "../SingleBlogPage/SingleBlogPage.jsx";
import ConsumerGoods from "../Medical_Sector/Consumer_Goods.jsx";
import MedicalSector from "../Medical_Sector/Medical_Sector.jsx";
import ItPage from "../ItPage/ItPage.jsx";
import Govt_API from "../Govt_API/Govt_API";
import Hr_Portal from "../Portal/HR_Portal";
import Self_Verification from "../Portal/Self_Verification.jsx";
import Offer_Portal from "../Portal/Offer_Portal.jsx";
import GR_Portal from "../Portal/GR_Portal.jsx";
import FMCG from "../Medical_Sector/FMCG.jsx";
import PageForCities from "../PagesForCities/PageForCities.jsx";


const AllRoutes = () => {
  return (
    <div>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>

        <Route path="/" exact element={<HomePage />} />
        <Route path="/about-us" element={<AboutSection />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/book-demo" element={<BookDemoPage />} />
        {/* <Route path="/blogs/blog/:id" element={<SingleBlogPage />} /> */}
        <Route path="/blogs/:slug" element={<SingleBlogPage />} />
        <Route path="/city-page/:slug" element={<PageForCities />} />

        <Route path="/industries" element={<Industries />}>
          {/* Child routes */}
          <Route path="banking" element={<Banking />} />
          <Route index element={<Navigate to="banking" replace />} />
          <Route path="healthcare" element={<MedicalSector />} />
          <Route path="online-gaming" element={<Games />} />
          <Route path="education" element={<Education />} />
          <Route path="manufacturing" element={<Manufacture />} />
          <Route path="it-ites" element={<ItPage />} />
          <Route path="travel" element={<Travel />} />
          <Route path="gig-workers" element={<GigWorkers />} />
          <Route path="ecommerce" element={<ECommerce />} />
          <Route path="logistics" element={<Logistics />} />
          <Route path="consumer-goods" element={<ConsumerGoods />} />
          <Route path="fmcg" element={<FMCG />} />
        </Route>

        {/* -------------------------govt apis--------------------------------- */}
        <Route path="/govt-api" element={<Govt_API />} />

        {/* ----------------portals--------------------------- */}
        <Route path="/hr-portal" element={<Hr_Portal />} />
        <Route path="/self-verification" element={<Self_Verification />} />
        <Route path="/offer-portal" element={<Offer_Portal />} />
        <Route path="/grievance-portal" element={<GR_Portal />} />


        {/* -----------------notFound page--------------------- */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {/* </Suspense> */}
    </div>
  );
};

export default AllRoutes;
