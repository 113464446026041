import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import ServicesCardSection from "../ItPage/ServicesCardSection/ServicesCardSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const E_Commerce = `${digitalOceanURL}/Sector/E-Commerce.png`;
export default function ECommerce() {

  return (
    <div>
      <div className=" relative  background_color ">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="ecommerce-img hero img"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center">

            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing ">
              <div className="mx-4 lg:mx-0">
                <span className="industry_info">E-commerce and Retail</span>
                <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                  Strengthening Trust in E-commerce and Retail Environments</h1>
                <h2 className="about-text-para mt-2 ">
                  Enhance your operations with our AI-driven background verification solutions, ensuring secure onboarding for staff, sellers, and customers in the industry.
                </h2>
              </div>

              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:top-12 bottom_space mb-20 lg:mb-0">
              <img
                src={E_Commerce}
                alt="about-girl img"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="top_Space">
        <BrandCarousel />
      </div>
      {/* 3 section */}
      <BgvServiceSection Sector="E-Commerce Sector" />
      {/* 4 section */}
      {/*  <BgvStepsSection /> */}
      {/* 5 section */}
      <ServicesCardSection Sector="E-Commerce Sector" Sector_cards='ecom' />
      <div className="mb-48">
        {<BgvStepsSection />}
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
