
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
import TestimonialSection from "../HomePage/TestimonialSection/TestimonialSection";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import PortalSection3 from "./Portal_Section_3";
// import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import Self_Verification_Section from "./Self_Verification_Section"
import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";
const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Self_Verification_Image = `${digitalOceanURL}/Portal_Image/Self_Verification_Image.png`;
const Self_Verification_Small_Image = `${digitalOceanURL}/Portal_Image/Self_Verification_small_Image.png`;

export default function Self_Verification() {

    return (
        <div>
            <div className=" relative overflow-hidden blog_bg_image mb-24 lg:mb-0 background_color">
                {/* ---------------------------show this for lg and md device------------------------------------- */}
                <div className="lg:relative   lg:top-8 flex flex-col   lg:mx-12 top_image_gap ">
                    <div className=" flex flex-col lg:relative  lg:right-10 leading-tight z-[2] Self-portal-box ">
                        <div className="p-2 mt-4  lg:ml-16 top_spacing">
                            <div aria-label="Introducing the Hirestar.io Self Verification Portal" className="text-wrap mt-14 lg:mt-0 md:mt-20 text-heading-container  font-semibold font-[poppins] text-center heading ">
                                {/* <h1 className="text-[32px] md:text-[46px] lg:text-[46px]" aria-label="Introducing the Hirestar.io Self Verification Portal">
                                    Introducing the Hirestar.io <br /> <span className='text-[#1783C4]'>Self Verification Portal</span>
                                </h1> */}

                                {/* updated-----------heading */}
                                <h1 className="text-[32px] md:text-[46px] lg:text-[46px]" aria-label="Introducing the Hirestar.io" >
                                    Introducing The Hirestar.io
                                </h1>
                                <p className="text-[32px] md:text-[46px] lg:text-[46px] text-[#1783C4]" aria-label=" Self Verification Portal">
                                    Self Verification Portal
                                </p>
                            </div>
                            <div className="text-wrap px-2 lg:px-0 md:px-0  font-[poppins] font-[16px] mt-4 md:mt-6 lg:mt-4 text-center paragraph">
                                <p>
                                    Streamline hiring with secure, blockchain-based background checks{" "}
                                    <br className="hidden md:block lg:block " />
                                    Trust Hirestar.io for verified and transparent employee screening
                                </p>
                            </div>
                        </div>

                        {/* --------------------------buttons-------------------- */}
                        <ButtonComponent />
                    </div>

                    {/* -------------------------show this for large device-------------------------------------- */}
                    <div className="self-image">
                        {/* Home Image */}
                        <img
                            className="home-image lg:mb-12 lg:relative w-full lg:top-0"
                            alt="hero img for self verification"
                            title="home image for self verification"
                            src={Self_Verification_Image}
                            loading="lazy"
                        />
                    </div>

                    {/* -------------------------show this for small device-------------------------------------- */}
                    <div className="relative w-full max-w-sm mx-auto small-image mb-20">
                        <div className="overflow-hidden rounded-lg">
                            <div className="flex transition-transform duration-300 ease-in-out">
                                <img loading="lazy"
                                    src={Self_Verification_Small_Image}
                                    alt='hero img small for self verification'
                                    title='small screen hero img for self verification'
                                    className="w-full h-auto flex-shrink-0"
                                />
                            </div>
                        </div>
                    </div>

                    {/* ------------------------dot code to show  current image -------------------- */}
                </div>

            </div>
            <div className="mt-20">
                <BrandCarousel />
            </div>
            <Self_Verification_Section />
            <PortalSection3 heading="Self Verification Portal" Paragraph='Unlock the future of seamless HR processes with Hirestar.io, a cutting-edge background verification solution powered by blockchain technology. Streamline your pre-joining background verification effortlessly and elevate your HR management to unprecedented heights.' section="Self_Verification" />
            <BgvStepsSection />
            <div >
                <TestimonialSection />
            </div>
            <FaqComponent />
            <div className="relative top-28  left-0 right-0">
                <HelpCard />
            </div>
        </div>
    );
}
