const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

// https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets/NewClients/defa.svg
const client1 = `${digitalOceanURL}/Clients_PNG/Appzest.png`;
const client2 = `${digitalOceanURL}/Clients_PNG/ChitMonks-logo.png`;
const client3 = `${digitalOceanURL}/Clients_PNG/bluesemi-logo.png`;
// const client4 = `${digitalOceanURL}/Clients_PNG/camfil-logo.png`;
const client5 = `${digitalOceanURL}/Clients_PNG/crome-check-logo.png`;
const client6 = `${digitalOceanURL}/Clients_PNG/elogix-logo.png`;
// const client7 = `${digitalOceanURL}/Clients_PNG/first-view-logo.png`;
const client8 = `${digitalOceanURL}/Clients_PNG/t-hub-logo.png`;
const client9 = `${digitalOceanURL}/Clients_PNG/monitra-logo.png`;
const client10 = `${digitalOceanURL}/Clients_PNG/AriqT-logo.png`;
const client11 = `${digitalOceanURL}/Clients_PNG/JNTU.png`;
const client12 = `${digitalOceanURL}/Clients_PNG/appstek_corp_logo.png`;
const client13 = `${digitalOceanURL}/Clients_PNG/icici-bank-logo.png`;
const client14 = `${digitalOceanURL}/Clients_PNG/image 48.png`;
const client15 = `${digitalOceanURL}/Clients_PNG/image 49.png`;
const client16 = `${digitalOceanURL}/Clients_PNG/image 50.png`;
const client17 = `${digitalOceanURL}/Clients_PNG/kanerika.png`;
const client18 = `${digitalOceanURL}/Clients_PNG/startupindia.png`;
const client19 = `${digitalOceanURL}/Clients_PNG/startups.png`;
// --------------------------------------------
const client20 = `${digitalOceanURL}/NewClients/defa.svg`;
const client21 = `${digitalOceanURL}/NewClients/hpl.svg`;
const client22 = `${digitalOceanURL}/NewClients/91springboard.svg`;
const client23 = `${digitalOceanURL}/NewClients/blix.svg`;
const client24 = `${digitalOceanURL}/NewClients/goken.svg`;
const client25 = `${digitalOceanURL}/NewClients/jewelbox.svg`;
const client26 = `${digitalOceanURL}/NewClients/nasscom.svg`;
const client27 = `${digitalOceanURL}/NewClients/phenom.svg`;
const client28 = `${digitalOceanURL}/NewClients/sps.svg`;
const client29 = `${digitalOceanURL}/NewClients/tfg.svg`;
const client30 = `${digitalOceanURL}/NewClients/trade-fantasy.svg`;
const client31 = `${digitalOceanURL}/NewClients/volta.svg`;



const images = [
  client22,
  client23,
  client24,
  client25,
  client26,
  client27,
  client28,
  client29,
  client30,
  client31,
  client1,
  client2,
  client3,
  client5,
  client6,
  client8,
  client9,
  client10,
  client11,
  client12,
  client13,
  client14,
  client15,
  client16,
  client17,
  client18,
  client19,
  client20,
  client21,
];

const RightSlider = () => {
  // console.log(client20, client21, client22, client23, client24, client25, client26, client27, client28, client29, client30, client31);
  return (
    <div className="w-full p-3 overflow-hidden ">
      <div className="relative  w-full">
        <div className="animate-slide transition-transform duration-300 ease-in-out  gap-8 animate-slide-right flex">
          {[...images, ...images, ...images].map((src, index) => (
            <div
              key={index}
              className="flex-shrink-0 
              w-[175px] h-[100px] rounded-[20px] border border-[#CCCCCC]
          flex justify-center items-center hover:border-[#1B6BB4] hover:cursor-pointer
           hover:shadow-[0px_4px_15px_rgba(27,107,180,0.3)] shadow-md px-3"
            >
              <div className="client-company-logo p-2">
                <img
                  loading="lazy"
                  src={src}
                  alt={`Slide ${index + 1}`}
                  title={`client ${index + 1}`}
                  className="max-w-full max-h-full object-contain mix-blend-color-burn"
                  width="150" // Explicit width
                  height="75" // Explicit height
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSlider;
