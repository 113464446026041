import LeftSlider from "./LeftSlider/LeftSlider";
import RightSlider from "./RightSlider/RightSlider";
const OurClients = () => {
  return (
    <div className="w-full mt-44 md:mt-36 lg:mt-36 ">
      <div className=" text-center mb-14  slider-heading-container">
        {/* <h2 className=" font-semibold mb-6 md:text-[44px] lg:text-[48px] section_headings">
          Our Clients and <span className="text-[#1B8ACA]">Partners</span>
        </h2> */}

        {/* updated ------------------- heading */}
        <h2 className=" inline font-semibold mb-6 md:text-[44px] lg:text-[48px] section_headings">
          Our Clients and {" "}
        </h2>
        <h2 className=" inline font-semibold mb-6 md:text-[44px] lg:text-[48px] text-[#1B8ACA] section_headings">
          Partners
        </h2>
        {/* --------------------- */}
      </div>
      {/* bg-[url('url('/src/components/Carousel/client-wire.png)')] */}
      <div className="slider-container ">
        <LeftSlider />
        {/* Right Slider */}
        <RightSlider />
      </div>
    </div>
  );
};

export default OurClients;
