import { Link } from "react-router-dom";
import { memo } from "react";
import slugify from "slugify";

const BlogCard = ({ blog }) => {
  const blogSlug = slugify(blog.title, { lower: true, strict: true });
  return (
    <Link
      // to={`/blogs/blog/${blog.id}`}
      to={`/blogs/${blogSlug}`}
      className="w-full cursor-pointer max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto overflow-hidden shadow-sm hover:shadow-lg transition duration-300 border-b-4 border-transparent hover:border-[#2095D2] rounded-md bg-white"
    >
      <div className="relative">
        <img loading="lazy"
          src={blog.image}
          alt={blog.title}
          title={"title" + blog.title}
          className="w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover"
        />
        <div className="w-36 absolute rounded-md bottom-0 left-0 right-0 bg-[#2095D2] text-center p-2 md:p-2 lg:p-2 transform translate-y-1/2 mx-2 sm:mx-5">
          <span className="text-sm sm:text-sm font-semibold text-[#FFFFFF]">
            {blog.date}
          </span>
        </div>
      </div>
      <div className="p-3 mb-4 mt-4 sm:p-4 md:p-5 pt-6 sm:pt-8">
        <h2 className="text-xl text-black hover:text-[#2095D2] sm:text-xl md:text-2xl font-normal mb-2">
          {blog.title}
        </h2>
        <p className="text-[#9E9E9E] font-normal text-xs sm:text-sm line-clamp-4">
          {blog.description}
        </p>
      </div>
    </Link>
  );
};

export default memo(BlogCard);
