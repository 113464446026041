import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import BgvStepsSection from "../ItPage/BgvStepsSection/BgvStepsSection";
// import BgvServiceSection from "../ItPage/BgvServiceSection/BgvServiceSection";
// import BrandCarousel from "../ItPage/BrandCarousel/BrandCarousel";

import GovtApiCards from "./GovtApiCards";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { useLocation } from "react-router-dom";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const Govt_Image = `${digitalOceanURL}/Sector/Govt_API_Image.png`;

export default function Govt_API() {
  // fetch url path
  const location = useLocation();

  // Split the path and get the last part
  // const lastSegment = location.pathname.split('/').filter(Boolean).pop().replace(/-/g, ' ');
  let name = location.state?.name;
  // console.log("name", name)
  return (
    <div>
      <div className="mb-20 relative  background_color ">
        {/* Image that is hidden on small screens */}
        <img
          loading="lazy"
          src={bg_image}
          title="about-us-bg"
          alt="govt api background img"
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />

        <div className=" relative  w-full h-full ">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center  mx-1  ">
            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing">
              <span className="industry_info">
                {name === 'Explore All' ? "" : name}
              </span>
              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                All-in-One AI-Driven Fraud Prevention and Risk Management
              </h1>
              <p className="about-text-para mt-2 w-[80%]">
                Enhance your operations with solutions that comply with
                regulations and foster trust throughout your digital onboarding
                and verification processes.
              </p>

              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:mt-0 bottom_space">
              <img
                loading="lazy"
                src={Govt_Image}
                title="about girl for govt. api page"
                alt="about_girl img container"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      </div>


      <div className="section_spacing1">
        <GovtApiCards />
      </div>
      <div className="mb-56">
        <BgvStepsSection />
      </div>
      <FaqComponent />
      <div className="relative top-28  left-0 right-0">
        <HelpCard />
      </div>
    </div>
  );
}
